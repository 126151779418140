import revive_payload_client_JzrIsVxEsm from "/build/incl/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.3_eslint@8.55.0_rollup@2.79.1_sass@1.69.5_typescript@5.6.2_vite@4.5.1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hn6djjM18c from "/build/incl/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.3_eslint@8.55.0_rollup@2.79.1_sass@1.69.5_typescript@5.6.2_vite@4.5.1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RKfLO8wwES from "/build/incl/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.3_eslint@8.55.0_rollup@2.79.1_sass@1.69.5_typescript@5.6.2_vite@4.5.1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_STY6UYmgz9 from "/build/incl/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.3_eslint@8.55.0_rollup@2.79.1_sass@1.69.5_typescript@5.6.2_vite@4.5.1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_NO3jLVgCIV from "/build/incl/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@2.79.1_typescript@5.6.2_vue@3.3.11/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/build/incl/.nuxt/components.plugin.mjs";
import prefetch_client_jDpUQsaauF from "/build/incl/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.3_eslint@8.55.0_rollup@2.79.1_sass@1.69.5_typescript@5.6.2_vite@4.5.1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_25a6lsuqUH from "/build/incl/modules/apollo/runtime/plugin.ts";
import vuetify_icons_4hoPTQ9wg0 from "/build/incl/node_modules/.pnpm/vuetify-nuxt-module@0.8.0_rollup@2.79.1_typescript@5.6.2_vite@4.5.1_vue@3.3.11/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import vuetify_no_client_hints_FhdnBHyUct from "/build/incl/node_modules/.pnpm/vuetify-nuxt-module@0.8.0_rollup@2.79.1_typescript@5.6.2_vite@4.5.1_vue@3.3.11/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import pwa_client_dAO7sfG2cV from "/build/incl/node_modules/.pnpm/@vite-pwa+nuxt@0.3.5_rollup@2.79.1_vite@4.5.1_workbox-build@7.0.0_workbox-window@7.0.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_wm74Bw0uXd from "/build/incl/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.3_eslint@8.55.0_rollup@2.79.1_sass@1.69.5_typescript@5.6.2_vite@4.5.1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_p9BQe9QEmH from "/build/incl/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.3_eslint@8.55.0_rollup@2.79.1_sass@1.69.5_typescript@5.6.2_vite@4.5.1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import _01_openfeature_BLelqZ3bSX from "/build/incl/plugins/01.openfeature.ts";
import _02_posthog_TKSLzW8kjI from "/build/incl/plugins/02.posthog.ts";
import _03_crisp_P9wmxnXAa7 from "/build/incl/plugins/03.crisp.ts";
import _04_hydra_ojXlJPfA5b from "/build/incl/plugins/04.hydra.ts";
import _05_maintenance_mode_jpGqGkuDgg from "/build/incl/plugins/05.maintenance-mode.ts";
import _06_vue_tour_zS7KR3wnLV from "/build/incl/plugins/06.vue-tour.ts";
import vuetify_iVkXhhrIaI from "/build/incl/node_modules/.pnpm/vuetify-nuxt-module@0.8.0_rollup@2.79.1_typescript@5.6.2_vite@4.5.1_vue@3.3.11/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify.mjs";
export default [
  revive_payload_client_JzrIsVxEsm,
  unhead_hn6djjM18c,
  router_RKfLO8wwES,
  payload_client_STY6UYmgz9,
  plugin_vue3_NO3jLVgCIV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_jDpUQsaauF,
  plugin_25a6lsuqUH,
  vuetify_icons_4hoPTQ9wg0,
  vuetify_no_client_hints_FhdnBHyUct,
  pwa_client_dAO7sfG2cV,
  chunk_reload_client_wm74Bw0uXd,
  check_outdated_build_client_p9BQe9QEmH,
  _01_openfeature_BLelqZ3bSX,
  _02_posthog_TKSLzW8kjI,
  _03_crisp_P9wmxnXAa7,
  _04_hydra_ojXlJPfA5b,
  _05_maintenance_mode_jpGqGkuDgg,
  _06_vue_tour_zS7KR3wnLV,
  vuetify_iVkXhhrIaI
]