import { OpenFeature, ProviderEvents } from '@openfeature/web-sdk'
import { defineStore } from 'pinia'

export const useOpenFeature = defineStore('openfeature', () => {
  const { $of } = useNuxtApp()

  const booleans: { [key: string]: boolean } = reactive({})
  function getBool(key: string, defaultValue: boolean = false) {
    if (booleans[key] === undefined) {
      booleans[key] = $of.getBooleanValue(key, defaultValue)
    }

    return booleans[key]
  }

  const numbers: { [key: string]: number } = reactive({})
  function getNumber(key: string, defaultValue: number = 0) {
    if (numbers[key] === undefined) {
      numbers[key] = $of.getNumberValue(key, defaultValue)
    }

    return numbers[key]
  }

  const strings: { [key: string]: string } = reactive({})
  function getString(key: string, defaultValue: string = '') {
    if (strings[key] === undefined) {
      strings[key] = $of.getStringValue(key, defaultValue)
    }

    return strings[key]
  }

  const objects: { [key: string]: any } = reactive({})

  type Video = { mux_playback_id: string; thumbnail_time: number }
  type Price = { dollars: number; discount: number; stripe_id: string }
  type Prices = {
    ayce_for_two: Price
    lead_the_team: Price
    taste_for_two: Price
  }

  function getObject(key: 'videos'): { [key: string]: Video }
  function getObject(key: 'asset_type_ids'): {
    poster: number
    music_release: number
    location_release: number
  }
  function getObject(key: 'live'): {
    live: boolean
    name: string
    starts: string
    mux_playback_id?: string
  }
  function getObject(key: 'pricing'): {
    public: Prices
    filmmaker?: Prices
    filmmakerDiscountCode?: string
  }
  function getObject(key: string, defaultValue?: any): { [key: string]: any }
  function getObject(key: string, defaultValue: any = {}) {
    if (objects[key] === undefined) {
      objects[key] = $of.getObjectValue(key, defaultValue)
    }

    return objects[key]
  }

  function refresh() {
    for (const key in booleans) {
      booleans[key] = $of.getBooleanValue(key, booleans[key])
    }
    for (const key in numbers) {
      numbers[key] = $of.getNumberValue(key, numbers[key])
    }
    for (const key in strings) {
      strings[key] = $of.getStringValue(key, strings[key])
    }
    for (const key in objects) {
      objects[key] = $of.getObjectValue(key, objects[key])
    }
  }

  $of.addHandler(ProviderEvents.Ready, refresh)
  $of.addHandler(ProviderEvents.ConfigurationChanged, refresh)

  const mergeContext = (context: any) => {
    OpenFeature.setContext({
      ...OpenFeature.getContext(),
      ...context,
    })
  }

  return {
    booleans,
    numbers,
    strings,
    objects,

    getBool,
    getNumber,
    getString,
    getObject,

    refresh,
    mergeContext,
  }
})
