import { OpenFeature } from '@openfeature/web-sdk'
import { Crisp } from 'crisp-sdk-web'
import { UserManager, WebStorageStateStore } from 'oidc-client-ts'

export default defineNuxtPlugin(async nuxtApp => {
  const route = nuxtApp._route
  const apollo = useApollo()
  const config = useRuntimeConfig()
  const { $posthog } = useNuxtApp()

  const um = new UserManager({
    authority: `https://${config.public.hydra.domain}`,
    metadataUrl: `https://${config.public.hydra.domain}/.well-known/openid-configuration`,
    client_id: config.public.hydra.clientId,
    redirect_uri: window.location.origin,
    scope: 'openid offline email',
    extraQueryParams: { audience: 'https://hasura.sato48.com' },
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  })

  um.events.addUserLoaded(u => {
    apollo.onLogin(u.access_token)
  })

  if (route.query.code) {
    try {
      await um.signinRedirectCallback(route.fullPath)
    } catch (e) {
      console.error(e)
    }

    onNuxtReady(async () => {
      await navigateTo({ ...route, query: {} }, { replace: true })
    })
  }

  let user = await um.getUser()
  if (user) {
    if (user.expired) {
      try {
        user = await um.signinSilent()
        if (!user) {
          await um.signinRedirect()
          return
        }
      } catch (e) {
        await um.signinRedirect()
        return
      }
    }

    let lastYear = false
    if (window.localStorage.getItem('sato48:lastYear')) {
      lastYear = true
    }

    // Set OpenFeature Context
    const roles: string[] = (user.profile.roles as string[]) ?? []
    const hasuraRoles: string[] =
      (user.profile['https://sato48.com/hasura'][
        'x-hasura-allowed-roles'
      ] as string[]) ?? []
    await OpenFeature.setContext({
      targetingKey: user.profile.email!,
      lastYear,
      super: roles.includes('Super User'),
      fiveFestAcademy: roles.includes('2025 FiveFest Academy'),
      selection: roles.includes('2025 Selection Committee'),
      prizeSponsor: roles.includes('2025 Prize Sponsor'),
      judge: roles.includes('2025 International Judge'),
      online_screening: hasuraRoles.includes('2025-online-screening'),
    })

    // Identify PostHog User
    $posthog().identify(user.profile.sub, {
      email: user.profile.email,
      name: user.profile.name,
    })

    // Set Crisp User
    Crisp.user.setEmail(user.profile.email!)
    Crisp.user.setNickname(user.profile.name!)
    // Crisp.user.setAvatar(user.profile.picture!)

    // Set Apollo Token
    apollo.onLogin(user.access_token)
  } else {
    await OpenFeature.setContext({
      targetingKey: 'anonymous',
      super: false,
    })
  }

  return {
    provide: {
      um,
    },
  }
})
