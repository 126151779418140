<template lang="pug">
v-app
  VitePwaManifest
  v-app-bar(:title="`SATO48-2025 Online Screening`")
  slot
  NotifySnackbar
</template>

<script setup lang="ts">
useHead({
  titleTemplate: (title) => {
    return title
      ? `${title} - SATO48-2025 Online Screening`
      : 'SATO48-2025 Online Screening'
  },
})
</script>
